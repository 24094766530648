<template>
  <div class="my-1">
    <div class="d-flex">
      <div
        v-for="(carriage, index) of seatMap"
        :key="`row-wrap-${index}`"
        :class="`d-flex flex-column
        ${index === seatMap.length - 1 ? 'pr-2' : ''}
        `"
      >
        <div class="text-center mb-2 fw-600">
          Khoang {{ carriage.name }}
        </div>
        <div
          v-for="(rows, indexRows) of carriage.data"
          :key="`seat-${indexRows}`"
          class="d-flex"
        >
          <div
            v-for="seat of rows"
            :key="seat"
          >
            <TicketBedButton
              :seat="resolveDataSeat(availSeats, seat, itineraryIndex)"
              :isRight="isRightSeat(seat)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- BED -->
<script>
// import { ref, toRefs } from '@vue/composition-api'
// import { BRow, BCol } from 'bootstrap-vue'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    TicketBedButton: () => import('./TicketBedButton.vue'),
  },
  props: {
    availSeats: {
      type: Array,
      default: () => [],
    },
    itineraryIndex: {
      type: Number,
      default: () => 0,
    },
  },
  setup() { // NOTE: layout bed
    const {
      resolveDataSeat,
    } = useTrainHandle()

    const seatMap = [
      {
        name: 1,
        data: [
          [3, 4],
          [1, 2],
        ],
      },
      {
        name: 2,
        data: [
          [7, 8],
          [5, 6],
        ],
      },
      {
        name: 3,
        data: [
          [11, 12],
          [9, 10],
        ],
      },
      {
        name: 4,
        data: [
          [15, 16],
          [13, 14],
        ],
      },
      {
        name: 5,
        data: [
          [19, 20],
          [17, 18],
        ],
      },
      {
        name: 6,
        data: [
          [23, 24],
          [21, 22],
        ],
      },
      {
        name: 7,
        data: [
          [27, 28],
          [25, 26],
        ],
      },
      {
        name: 8,
        data: [
          [31, 32],
          [29, 30],
        ],
      },
    ]

    function isRightSeat(index) {
      return index % 2 !== 0
    }

    return {
      seatMap,
      isRightSeat,
      resolveDataSeat,
    }
  },
}
</script>

<style lang="">

</style>
